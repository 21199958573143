export const puapParts = [
 "DriveShaft"
  ];

  // home
//   "Steering Rack/Box/Gear",
// "Exhaust Manifold",
// "Carrier",
// "Cylinder Head",
// "CV Axle Shaft",
// "Struts",
// "Climate Control Module",
// "Mirror Door",
// "Front Differential",
// "Rear Differential",
// "Rear Axle Assembly (Rear End)",
// "Front Axle Assembly",
// "Others"

  // Axle
// "Front Differential",
// "Rear Differential",
// "Rear Axle Assembly (Rear End)",
// "Front Axle Assembly",
// "Carrier",
// "CV Axle Shaft",
// "Others"

// mixed
// "Steering Rack/Box/Gear",
// "Exhaust Manifold",
// "Cylinder Head",
// "Struts",
// "Climate Control Module",
// "Mirror Door",
// "Others"