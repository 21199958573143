import axios from "axios";
import React, { useState, useEffect, forwardRef,useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {z} from "zod";
import Select from "react-select";
import InputMask from "react-input-mask";
import { getUserAgentInfo, getLocationInfo, postInfo } from "./api"; 
import { puapYears } from "./puapYears";
import { puapMakes } from "./puapMakes";
import { puapModels } from "./puapModels";
import { blockListDomains } from "./blockListDomains";
import "./leadform.css";
import {
  ArrowUUpLeft,
  Check,
  PhoneOutgoing
} from "@phosphor-icons/react";
import { puapParts } from "./puapParts";
import ReCAPTCHA from 'react-google-recaptcha';
let partInfo,
  vehicleInfo,
  contactInfo,
  personalInfo,
  locationInfo,
  browserInfo;

const carFormSchema = z.object({
  year: z
    .object({ value: z.string(), label: z.string() })
    .nullable()
    .refine(val => val !== null, {
      message: "Year is required",
    }),
  make: z
    .object({ value: z.string(), label: z.string() })
    .nullable()
    .refine(val => val !== null, {
      message: "Car make is required",
    }),
  model: z
    .object({ value: z.string(), label: z.string() })
    .nullable()
    .refine(val => val !== null, {
      message: "Car model is required",
    }),
  part: z
    .object({ value: z.string(), label: z.string() })
    .nullable()
    .refine(val => val !== null, {
      message: "Part is required",
    }),
});

const personalInfoSchema = z.object({
  fullName: z
    .string()
    .min(2, { message: "Please enter a valid full name" }),
    // .regex(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/, {
    //   message: "Please enter a valid full name",
    // }),
  phoneNumber: z.string().regex(/^\+1 \(\d{3}\) \d{3} \d{4}$/, {
    message: "Invalid phone number",
  }),
  email: z
    .string()
    .regex(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, {
      message: "Invalid email address",
    })
    .refine(value => {
      return !blockListDomains.some(domain => value.endsWith(domain));
    }, "We couldn't verify this email address"),
  zipCode: z
    .string()
    .refine(value => !value || /^\d{5}$/.test(value), "Invalid zip code"),
});

const generateRandomNumber = () => {
  return Math.floor(Math.random() * 10000);
};

const CustomInputMask = forwardRef((props, ref) => (
  <InputMask
    {...props}
    ref={inputRef => {
      if (ref) {
        if (typeof ref === "function") {
          ref(inputRef);
        } else {
          ref.current = inputRef;
        }
      }
    }}
  />
));

CustomInputMask.displayName = "CustomInputMask";

const CarSelectionForm = () => {
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const recaptchaRef = useRef();
  // const [showPopup, setShowPopup] = useState(false);

  const {
    control: carControl,
    handleSubmit: handleCarSubmit,
    reset: resetVehicleForm,
    watch,
    setValue,
    formState: { errors: carErrors },
  } = useForm({
    defaultValues: {
      make: null,
      model: null,
      year: null,
      part: {value:"DriveShaft",label:"DriveShaft"}
       
    },
    resolver: zodResolver(carFormSchema),
  });

  const {
    control: personalControl,
    handleSubmit: handlePersonalSubmit,
    reset: resetPersonalForm,
    formState: { errors: personalErrors  },
  } = useForm({
    defaultValues: {
      fullName: "",
      email: "",
      phoneNumber: "",
      zipCode: "",
    },
    resolver: zodResolver(personalInfoSchema),
    shouldFocusError: false,
    mode: "onBlur",
    });

  const selectedMake = watch("make");

  React.useEffect(() => {
    setValue("model", null);
  }, [selectedMake, setValue]);

  const onCarSubmit = async data => {
    partInfo = { ...data };
    vehicleInfo = {
      year: partInfo.year.label,
      make: partInfo.make.label,
      model: partInfo.model.label,
      part: partInfo.part.label,
    };
    localStorage.setItem("partInfo", JSON.stringify(partInfo));
    handleGetInfo();
    setLoading(true);
    setError(null);
    setIsVisible(!isVisible);

    try {
    } catch (err) {
      setError("An error occurred while submitting car selection.");
    } finally {
      setLoading(false);
    }
  };
  
  const onPersonalSubmit = async data => {
    setLoading(true);
    personalInfo = { ...data };
    contactInfo = JSON.stringify({ personalInfo });
    const dataToSend = {
      personalInfo,
      vehicleInfo,
      locationInfo,
      browserInfo,
    };
  
    localStorage.setItem("personalInfo", JSON.stringify(personalInfo));
  
    try {
      const recaptchaToken = await recaptchaRef.current.executeAsync();
       console.log(recaptchaToken);
      recaptchaRef.current.reset();
      const dataWithCaptcha = {
        ...dataToSend,
        recaptchaToken
      };
      const submissionTimestamp = Date.now();
      sessionStorage.setItem("lastFormSubmission", submissionTimestamp);
      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
         "https://leadformbackend-275257620739.us-central1.run.app/api/puap/leadformEmail",
        true
      );
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.send(JSON.stringify(dataWithCaptcha));
setLoading(true);
      setTimeout(() => {
          setLoading(false);
                      resetPersonalForm();
            window.location.href = "/thank-you.html";
            // setShowPopup(true);
      }, 600);
    } catch (err) {
      setError("An error occurred while submitting information.");
    } finally {
      console.log("Finally");
    }
  };
  
  // const closePopup = (event) => {
  //        window.location.href = "/";
  //       event.preventDefault();
  //   setShowPopup(false);
  // };

  const [browserInfo, setBrowserInfo] = useState({});
  const [locationInfo, setLocationInfo] = useState({});
  const [isPostEnabled, setIsPostEnabled] = useState(false);

  useEffect(() => {
    const fetchBrowserInfo = async () => {
      const info = await getUserAgentInfo();
      setBrowserInfo(info);
    };

    fetchBrowserInfo();
  }, []);

  const handleGetInfo = async () => {
    try {
      const location = await getLocationInfo();
      setLocationInfo(location);
      localStorage.setItem("locationInfo", JSON.stringify(location));
    } catch (error) {
      console.error("Failed to get location info:", error);
    }
  };

  const handlePostInfo = async () => {
    const dataToSend = { partInfo, browserInfo, locationInfo };
    try {
      const tokenResponse = await postInfo(dataToSend);
      if (tokenResponse !== "invalid") {
        setToken(tokenResponse);
        setIsPostEnabled(true);
        localStorage.setItem("partInfo", JSON.stringify(partInfo));
      }
    } catch (error) {
      console.error("Failed to post info:", error);
    }
  };

  const [isVisible, setIsVisible] = useState(false);
  return (
<div className="lead_form_container">
   {!isVisible && (
    <div>
           <div className="flex">
            <h2 className="heading" style={{fontSize:"2rem"}}>Find Drive Shaft</h2>
           </div>
           <form
            onSubmit={handleCarSubmit(onCarSubmit)}
            className=""
            autoComplete="off"
            >
           <div className="form_label">
                <label htmlFor="year" className="puap_lable">
                  Select Year
                </label>
                <Controller
                  name="year"
                  control={carControl}
                  render={({ field }) => (
                    <Select
                      {...field}

                      placeholder="Click here to Select Year*"
                      aria-labelledby="Select Year"
                      aria-label="Select Year"
                      inputId="year"
                       id="year"
                      options={puapYears.map(year => ({
                        value: year,
                        label: year,
                      }))}
                      className="puap_form"
                      classNamePrefix="react-select"
                    />
                  )}
                />
              </div>
              <div>
              {carErrors.year && (
                <p className="puap_error">{carErrors.year.message}</p>
              )}
            </div>

            <div className="form_label">
                <label htmlFor="make" className="puap_lable">
                  Select Make
                </label>
                <Controller
                  name="make"
                  control={carControl}
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="Click here to Select Make*"
                      aria-labelledby="Select Make"
                      aria-label="Select Make"
                      inputId="make"
                      id="make"
                      options={puapMakes.map(make => ({
                        value: make,
                        label: make,
                      }))}
                      className="puap_form"
                      classNamePrefix="react-select"
                    />
                  )}
                />
              </div>
              <div>
              {carErrors.model && (
                <p className="puap_error">{carErrors.make?.message}</p>
              )}
            </div>
            <div className="form_label">
                <label htmlFor="model" className="puap_lable">
                  Select Model
                </label>
                <Controller
                  name="model"
                  control={carControl}
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="Click here to Select Model*"
                      aria-labelledby="Select Model"
                      aria-label="Select Model"
                      inputId="model"
                      id="model"
                      options={
                        selectedMake
                          ? puapModels[selectedMake.value].map(model => ({
                              value: model,
                              label: model,
                            }))
                          : []
                      }
                      noOptionsMessage={() =>
                        "Select a vehicle make to view available models"
                      }
                      className="puap_form"
                      classNamePrefix="react-select"
                    />
                  )}
                />
              </div>
              <div>
              {carErrors.model && (
                <p className="puap_error">{carErrors.model.message}</p>
              )}
            </div>
            <div className="form_label">
                <label htmlFor="part" className="puap_lable">
                  Select Part
                </label>
                <Controller
                  name="part"
                  control={carControl}
                  
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="Click here to Select Part*"
                      aria-labelledby="Select Part"
                      aria-label="Select Part"
                      options={puapParts.map(part => ({
                        value: part,
                        label: part,
                      }))}
                      inputId="part"
                       id="part"
                      className="puap_form"
                      classNamePrefix="react-select"
                    />

                  )}
                  
                />
              </div>
              <div>
              {carErrors.part && (
                <p className="puap_error">{carErrors.part.message}</p>
              )}
            </div>
            <div className="puap_form_button">
              <button
                type="submit"
                
                className="puap_form_button_in"
                disabled={loading}
                 >
                {loading ? "Searching..." : "Search"}
              </button>
            </div>
           </form>

    </div>
   )} 

{isVisible && (
    <div>
           <div className="flex">
            <h2 className="heading" style={{display:"flex",alignItems:"center"}}>
             <Check className="circle" size={30} color="white" />
            Yes, We have the part in stock! <br />Please provide us the below information, so we can send you an instant quote
            </h2>
           </div>
           <div className="selected_items">
            <div className="puap_form_car_detail">
              {partInfo.part.value} for {partInfo.make.value}{" "}
              {partInfo.model.value} ({partInfo.year.value})
            </div>
            <div
              className="puap_form_car_detail_back"
              onClick={() => setIsVisible(!isVisible)}
            >
              <ArrowUUpLeft className="puap_lf_icc_back_icon" />
              <span className="puap_lf_back_text"> Change</span>
            </div>
          </div>
           <form
            onSubmit={handlePersonalSubmit(onPersonalSubmit)}
            className=""
            autoComplete="off"
            >
    
           <div className="form_label_prl">
                <label htmlFor="fullName" className="puap_lable">
                FULL NAME
                </label>
                <Controller
                  name="fullName"
                 
                  className="syneCaps"
                  control={personalControl}
              
                  render={({ field }) => (
                    <input
                    {...field}
                    type="text"
                     id="fullName"
                    placeholder="Enter Your Name"
                    className="puap_input_box"
                    autoComplete="one-time-code"
                    maxLength={32}
                    onChange={(e) => {
                      const value = e.target.value;
                      const formattedValue = value.replace(/\b\w/g, char => char.toUpperCase());
                      field.onChange(formattedValue); // Update the form state
                    }}
                  />
                  )}
                />
              </div>
             
              <div>
              {personalErrors.fullName && (
                <p className="puap_error">
                  {personalErrors.fullName.message}
                </p>
              )}
            </div>

            <div className="form_label_prl">
                <label htmlFor="phoneNumber" className="puap_lable">
                  PHONE NUMBER
                </label>
                <Controller
                  name="phoneNumber"
                  
                  control={personalControl}
                  render={({ field }) => (
                    <InputMask
                      {...field}
                      mask="+1 (999) 999 9999"
                      id="phoneNumber"
                      className="puap_input_box"
                      placeholder="+1  Enter Your Number"
                      autoComplete="one-time-code"
                    />
                  )}
                />
              </div>
              <div>
              {personalErrors.phoneNumber && (
                <p className="puap_error">
                  {personalErrors.phoneNumber.message}
                </p>
              )}
            </div>

            <div className="form_label_prl">
                <label htmlFor="email" className="puap_lable">
                  Email address
                </label>
                <Controller
                  name="email"
                 
                  className="syneCaps"
                  control={personalControl}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="text"
                       id="email"
                      className="puap_input_box"
                      autoComplete="one-time-code"
                      placeholder="Enter Your Email"

                    />
                  )}
                />
              </div>
              <div>
              {personalErrors.email && (
                <p className="puap_error">
                  {personalErrors.email.message}
                </p>
              )}
            </div>
            <div className="form_label_prl">
                <label htmlFor="zipCode">
                  {" "}
                  <span className="puap_lable">ZIP CODE </span>
                  <span className="puap_lf_lbl_optional">
                    {" "}
                    (Optional)
                  </span>
                </label>
                <Controller
                  name="zipCode"
                  
                  control={personalControl}
                  render={({ field }) => (
                    <InputMask
                      {...field}
                      mask="99999"
                       id="zipCode"
                      className="puap_input_box"
                      placeholder="Enter your ZipCode"
                      autoComplete="one-time-code"
                      
                    />
                  )}
                />
              </div>
              <div>
              {personalErrors.zipCode && (
                <p className="puap_error">
                  {personalErrors.zipCode.message}
                </p>
              )}
            </div>
            <ReCAPTCHA
        sitekey="6LfOp4MqAAAAAOs8iBrjnMEng-Wpts2Cny_9huGj"
        size="invisible"
        ref={recaptchaRef}
      />   
            <div className="puap_form_button">
              <button type="submit" className="puap-google-tag-submit"          
                disabled={loading}
                >
                {loading ? "Searching..." : "Get Instant Quote"}
                
              </button>
            </div>
           </form>
            {/* {showPopup && (
        <div className="popup">
        <div className="popup-content">
       
          <div class="thank-you-page" >
            <div style={{display:"flex",justifyContent:"space-between"}}>
  <p style={{textAlign: "center",fontSize:"3rem",paddingBottom:"16px"}}>Hi, <strong><span>{personalInfo.fullName}</span></strong></p>
              <button style={{borderWidth:"0",background:"white",fontSize:"25px",paddingBottom:"10px"}} onClick={closePopup}>&#10006;</button>
</div>  
  <p style={{paddingBottom:"16px"}}>Thank you for your part request! Our Parts Specialist will be contacting you at <strong>{personalInfo.phoneNumber}</strong> shortly to confirm the details. Please check your inbox for a confirmation email sent to <strong>{personalInfo.email}</strong> </p>
  <p>Feel free to give us a call right away for a <strong>10% discount</strong> and immediate assistance during our business hours, which are from 7 am to 4 pm Pacific Time, Monday to Friday.</p>
<p style={{paddingTop:"16px"}}>We appreciate your business!</p>
<p style={{paddingTop:"4px",paddingBottom:"16px"}}>If you need immediate assistance, Please do call us right away!</p>
<div class="hero-cta" style={{textAlign: "center"}}> 
  <a href="tel:1-800-606-1107" class="hero-c-t-a" ><PhoneOutgoing size={25} />1-800-606-1107</a>
</div>
</div>
        </div>
      </div>
      )}  */}
    </div>
   )} 
</div>
  );
};

export default CarSelectionForm;
